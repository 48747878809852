import React from 'react'
import Layout from '../../../components/layout'
import ArticlesPageComponent from "../../../components/pages/articles";

const embedded = true;
const ArticlesPageEmbedded = () => {
  return(
    <Layout embedded={embedded}>
      <ArticlesPageComponent embedded={embedded} webViewVersion="v1" />
    </Layout>
  )
}

export default ArticlesPageEmbedded